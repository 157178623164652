<template>
  <v-container fluid>

      <!-- Section Path File/Modul -->
      <v-breadcrumbs 
          :items="pathModul"
          divider="-"
          normal
          class="pathModul"
      ></v-breadcrumbs>

      <!-- Section Filtering Input/Select/Button -->
      <v-container fluid :class="$vuetify.breakpoint.lgAndUp? 'px-6' : 'px-1'">

        <v-row align="center">

          <v-col cols="3" class="col-input-filter">
              <v-select
                v-on:change="selectValueStation"
                :items="itemStation"
                item-text="stationdisplay"
                item-value="stationvalue"
                label="Station"
                prepend-inner-icon="mdi-store-marker"
                hint="Station" hide-details
                solo dense
              ></v-select>
          </v-col>

          <!-- Column Select Parameter -->
          <v-col cols="3" class="col-input-filter" >
              <v-select
                v-model="arraySelectedParam"
                :items="listParam"
                item-text="paramdisplay"
                item-value="paramvalue"
                label="Parameter"
                prepend-inner-icon="mdi-beaker-outline"
                multiple persistent-hint
                hide-details solo dense
                :no-data-text="valStation == null? 'Please select station first' : 'No data available'"
                @change="checkedParam($event)"
              ></v-select>
          </v-col>

          <!-- Column Select Calendar -->
          <v-col cols="3" class="col-input-filter">
              <v-menu
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
                v-model="modalCalendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateFormat"
                    label="Date"
                    prepend-inner-icon="mdi-calendar-month"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    hide-details
                    solo dense
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-on:change="selectDate"
                  v-model="dateFormat"
                  @input="modalCalendar = false"
                  type="month"
                ></v-date-picker>
              </v-menu>
          </v-col>

          <!-- Column Generate Button -->
          <v-col>
              <v-btn
              :disabled="valStation == null || arraySelectedParam.length == 0"
              class="white--text button mt-3"
              solo dense normal
              @click="generateChart()"
              :loading="loadingReport"
              >
                GENERATE REPORT
              </v-btn>
          </v-col>

        </v-row>

        <v-card>

          <!-- Main Report Title/Tab -->
          <v-tabs>
              <v-tab>MONTHLY</v-tab>
          </v-tabs>

          <div style="max-height: calc(100vh - 360px);overflow-y:auto;overflow-x:hidden;">
            <!-- Genarate Chart -->
            <highcharts 
              :constructorType="'stockChart'" 
              class="hc px-6" 
              :options="chartOptions" 
              ref="chart"
            >
            </highcharts>

            <v-divider class="pb-4"></v-divider>

            <!---- Export Button ---->
            <v-row class="justify-end px-10 pb-0">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                  color="excel"
                  class="mb-5"
                  style="float: right;"
                  elevation="2"
                  v-bind="attrs"
                  v-on="on"
                  @click="exportExcel()"
                  >
                    <v-icon
                    color="white"
                    center
                    dark
                    >
                        mdi-microsoft-excel
                    </v-icon>
                  </v-btn>
                </template>
                <span>Export to Excel</span>
              </v-tooltip>
              <!-- <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                  color="pdf"
                  class="mb-5 mx-2"
                  style="float: right;"
                  elevation="2"
                  v-bind="attrs"
                  v-on="on"
                  @click="exportReport('pdf')"
                  >
                    <v-icon
                    color="white"
                    center
                    dark
                    >
                        mdi-file-pdf-box
                    </v-icon>
                  </v-btn>
                </template>
                <span>Export to PDF</span>
              </v-tooltip> -->
              <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                  <v-btn
                  color="csv"
                  class="mb-5 mx-2"
                  style="float: right;"
                  elevation="2"
                  v-bind="attrs"
                  v-on="on"
                  @click="exportCSV()"
                  >
                    <v-icon
                    color="white"
                    center
                    dark
                    >
                        mdi-file-delimited
                    </v-icon>
                  </v-btn>
                </template>
                <span>Export to CSV</span>
              </v-tooltip>
            </v-row>

            <v-data-table
            :headers="dtheader"
            :items="dtbody"
            :items-per-page="itemPerPage"
            class="elevation-1 px-6 headerDtSarawak"
            fixed-header
            >
            
              <template
              v-slot:body="{ items }"
              >
                <tbody>
                  <tr
                  v-for="(item,x) in items"
                  :key="x"
                  >
                    <td v-for="(itemtd,i) in dtheader" :key="i" >
                      <!-- {{item[itemtd.value]}} -->
                      <span v-if="item[itemtd.value+'_FLAG'] != null" class="red--text font-weight-bold">
                        {{ item[itemtd.value+'_FLAG'] }}
                      </span>
                      <span v-else>
                        <span v-if="item[itemtd.value] == null" class="font-weight-bold">
                          NA
                        </span>
                        <span v-else>
                          {{ item[itemtd.value] }}
                        </span>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </template>

            </v-data-table>
          </div>

          <template>
            <v-footer padless>
              <v-col
                class="text-center"
                cols="12"
              >
                <span class="one-text-footer">
                  <span class="red--text">C</span>
                  <span class="blue--text">=CALIBRATION</span>
                </span>
                <span class="one-text-footer">
                  <span class="red--text">M</span>
                  <span class="blue--text">=MAINTENANCE</span>
                </span>
                <span class="one-text-footer dontBreak">
                  <span class="red--text">DOR</span>
                  <span class="blue--text">=DATA OUT OF SENSOR RANGE</span>
                </span><br>
                <span class="one-text-footer">
                  <span class="red--text">OUTL</span>
                  <span class="blue--text">=OUTLIER</span>
                </span>
                <span class="one-text-footer">
                  <span class="red--text">NA</span>
                  <span class="blue--text">=DATA NOT AVAILABLE</span>
                </span>
              </v-col>
            </v-footer>
          </template>
        
        </v-card>

      </v-container>
      
      <v-dialog 
        v-model="dialogAlert"
        width="500">

        <v-card>
          <v-card-title class="text-h5 white--text error">
              <v-icon class="white--text mr-4">mdi-alert</v-icon>Attention
          </v-card-title>

          <v-card-text class="pa-5 d-flex justify-center">
              <h3>Due to performance issue, you can only select maximum of 2 parameters only.</h3> 
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="dialogAlert = false;"
            >
                Close
            </v-btn>
          </v-card-actions>
        </v-card>
      
      </v-dialog>

      

  </v-container>
</template>


<script>

import axios from 'axios';
import Papa from "papaparse";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {

    
    data: () => ({

      originalDataParams: [],

      dialogAlert: false,
      GlobalDataMonthly : [],
      loadingReport: false,
      pathModul: [
          {
              text: 'Home',
              disabled: false,
              // href: '/',
              href: '/Mapviewer',
          },
          {
              text: 'Report - Monthly',
              disabled: true,
              href: '/Report/MonthlyReport',
          },
      ],
      itemStation: [],
      dateFormat: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7),
      menu: false,
      modalCalendar: false,
      menu2: false,
      valStation: null,
      valStd: null,
      stationTitle: null,
      valParameter: null,
      valDate: null,
      bulan: '',
      tahun: '',
      pdfExc: [],

      // Title
      locationStation: null,
      stationID: null,

      itemPerPage: 10,

      arraySelectedParam: [],
      listParam: [],
      chartOptions: {
        // chart: {
        //   type: 'spline'
        // },
        title: {
          text: 'WATER QUALITY STATUS'
        },
        subtitle: {
          text: ''
        },
        series: [],
        legend: {
            enabled: true
        },
      },
      dtbody: [],
      dtheader: [],
      mainParam: [],
      unit: []
      
    }),
    
    methods: {

      convert12Hours(time) {
          var H = +time.substr(0, 2);
          var h = H % 12 || 12;
          var ampm = (H < 12 || H === 24) ? "AM" : "PM";
          time = h + time.substr(2, 3) + time.substr(5, 3) + ' ' + ampm;
          return time;
      },

      convertDateFormat(date){
          var dd = date.split("-")[2];
          var mm = date.split("-")[1];
          var yyyy = date.split("-")[0];
          date = dd + "/" + mm + "/" + yyyy;
          return date;
      },

      // Anything need to load first refresh
      load(){
        this.getListStation();
        // this.getDataParam();
      },

      getListStation(){
        axios.get(this.globalUrl+'mqm2/miqims/stations', {
            headers: {
                'Authorization': 'Bearer ' + this.$store.getters.user.token,
            }
        })
        .then((response) => {
          let data = response.data;
          this.itemStation = [];

          console.log(data);

          let station = [];
          for(let k in data){
            let obj = {
                stationdisplay: data[k].STATION_ID + " - " + data[k].LOCATION,
                stationvalue : data[k].STATION_ID
            }

            station.push(obj)
          }

          this.itemStation = station;

          // for (let i = 0; i < response.data.length; i++) {
          //   this.itemStation.push(response.data[i].stationId + " - " + response.data[i].location);
          // }
            
        })
        .catch(error => {
            console.log(error);
        })
      },

      getDataParam(){

        // console.log("masuk",this.valStation)

        this.unit = [];
        this.listParam = [];
        axios.get(this.globalUrl+'mqm2/miqims/params?stationid='+this.valStation, {
            headers: {
                'Authorization': 'Bearer ' + this.$store.getters.user.token,
            }
        })
        .then((response) => {

          this.mainParam = response.data;
          let data = response.data;
          //var obj;

          this.originalDataParams = data;

          let param = [];
          for(let k in data){

            let obj = {
                paramdisplay: data[k].text,
                paramvalue : data[k].reading
            }

            // if(data[k].reading === 'pH'){
            //   obj = {
            //       paramdisplay: 'Sub-index '+ data[k].fullname +' (Index)',
            //       paramvalue : data[k].reading
            //   }
            // }

            // else if(data[k].reading === 'DO_SAT'){
            //   obj = {
            //       paramdisplay: 'Sub-index Dissolved Oxygen (Index)',
            //       paramvalue : data[k].reading
            //   }
            // }

            // else if(data[k].reading === 'TSS'){
            //   obj = {
            //       paramdisplay: 'Sub-index '+ data[k].fullname +' (Index)',
            //       paramvalue : data[k].reading
            //   }
            // }

            // else{
            //   obj = {
            //       paramdisplay: data[k].text,
            //       paramvalue : data[k].reading
            //   }
            // }

            //--------------------------------------------

            let objunit = {
                name: data[k].reading,
                value: data[k].unit
            }

            param.push(obj);
            this.unit.push(objunit);
          }

          this.listParam = param;

          // for (let i = 0; i < response.data.length; i++) {
          //   this.listParam.push(response.data[i].reading);
          // }

        })
        .catch(error => {
            console.log(error);
        })
      },

      // Get Data From Api
      getDataMonthly(station,tarikh){
        ///kasi error kalo param lebih dari 3 =

        if(this.valParameter.length > 2) 
        {
          alert('Sorry, due to performance issue, you can only select max 2 params only.');
          this.loadingReport = false;
          
        }

        if(this.valStation == null){
          alert('Please choose at least one station');
          this.loadingReport = false;
        }

        else{
          axios.get(this.globalUrl+'mqm2/miqims/monthly?startdt='+ tarikh +'-01&stationid='+ station + '&paramlist=' +  this.valParameter,{
              headers: {
                  'Authorization': 'Bearer ' + this.$store.getters.user.token,
              }
          })
          .then((response) => {

              // Globalkan Data
              this.GlobalDataMonthly = response.data;

              this.loadDataChart(response.data,this.valParameter,station);
              this.loadingReport=false;

          })
          .catch(error => {
              console.log(error);
          })
        }
      },

      exportCSV(){

        // let headerExportCsv = [];
        // for(let i in this.dtheader){
        //   headerExportCsv.push(this.dtheader[i]['value']);
        // }

        // let keys = headerExportCsv,
        // resultAfterFilter = this.dtbody.map(o => Object.assign(...keys.map(k => ({ [k]: o[k] }))));

        // let obj = resultAfterFilter;

        // for(let i in obj){

        //   obj[i]["Date"] =  obj[i]['datetime_a']; 
        //   // obj[i]["STATION ID"] =  obj[i]['station_a']; 
        //   // obj[i]["LOCATION "] =  obj[i]['LOCATION']; 

        //   delete obj[i]['datetime_a'];
        //   delete obj[i]['station_a'];
        //   delete obj[i]['LOCATION'];

        //   for(let j in this.mainParam){
        //     for(let k in this.arraySelectedParam){
        //       if(this.arraySelectedParam[k] === this.mainParam[j].reading){
        //         obj[i][this.mainParam[j].text] = obj[i][this.mainParam[j].reading];
        //         delete obj[i][this.mainParam[j].reading];
        //       }
        //     }
        //   }

        // }

        let listKeyHeader = [];
      for(let i in this.dtheader){
        listKeyHeader.push(this.dtheader[i].value)
      }

      let temptbody = this.dtbody

      let keysTbody = Object.keys(temptbody[0])
      for(let i in keysTbody){
        for(let x in temptbody){
          if(temptbody[x][keysTbody[i]] == null){
            temptbody[x][keysTbody[i]] = "NA";
          }
        }
      }

      console.log(temptbody);

      let keys = listKeyHeader,
        resultAfterFilter = temptbody.map((o) =>
          Object.assign(...keys.map((k) => ({ [k]: o[k] })))
        );
      let dataTbodyOnly = resultAfterFilter;

      let listKeyHeaderFullname = []
      for(let i in dataTbodyOnly){
        for(let x in listKeyHeader){
          for(let z in this.originalDataParams){
            if(listKeyHeader[x] == this.originalDataParams[z].value){
              listKeyHeaderFullname.push(this.originalDataParams[z].text);
              dataTbodyOnly[i][this.originalDataParams[z].text] = dataTbodyOnly[i][listKeyHeader[x]]
              // dataTbodyOnly[i]["Datetime"] = this.useConvertDisplayDatetime(dataTbodyOnly[i]["datetime_a"])
              dataTbodyOnly[i]["Datetime"] = dataTbodyOnly[i]["datetime_a"]
            }
          }
        }
      }
      let lastKeys = listKeyHeaderFullname.filter((item,
      index) => listKeyHeaderFullname.indexOf(item) === index);
      lastKeys.unshift("Datetime")

      console.log(dataTbodyOnly);

      let keyssss = lastKeys,
        lastResults = dataTbodyOnly.map((o) =>
          Object.assign(...keyssss.map((k) => ({ [k]: o[k] })))
        );
      let dataToExportCSV = lastResults;


        let blob = new Blob([Papa.unparse(dataToExportCSV)], { type: 'text/csv;charset=utf-8;' });
        let link = document.createElement("a");

        let url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", 'Monthly Report.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

      },

      exportExcel(){


        // Define your style class template.

        this.itemPerPage = -1;

        let headerName = "<tr = 'border:none !important' ><td colspan ='"+this.dtheader.length+"' style = 'text-align:center; border:none !important; font-weight: bold'>WATER QUALITY STATUS FOR "+ this.stationTitle.split("-")[1] + " ( "+ this.stationTitle.split("-")[0] + ") " + this.tahun + "</td></tr></td></tr>"
        var style = "<style> th, td { border: 1px solid black;}</style>";

        var uri = "data:application/vnd.ms-excel;base64,",
          template =
            '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->' +
            style +
            "</head><body><table>" +
            headerName +
            "{table}</table></body></html>",
          base64 = function (s) {
            return window.btoa(unescape(encodeURIComponent(s)));
          },
          format = function (s, c) {
            return s.replace(/{(\w+)}/g, function (m, p) {
              return c[p];
            });
          };

        setTimeout(() => {
          let table = document.querySelectorAll(".v-data-table__wrapper ");

          // console.log(table[0].innerHTML)

          let tablei = table[0].innerHTML;
          // if (tablei.includes("℃")){
          tablei = tablei.replaceAll("°C", "&#176;C").replaceAll("µ", "&#181;");


          let ctx = { worksheet: "Monthly Report" || "Worksheet", table: tablei };

          let a = document.createElement("a");
          a.href = uri + base64(format(template, ctx));
          a.download = "Monthly Report.xls";
          //triggering the function
          a.click();

          this.itemPerPage = 10;

        }, 1000)
      },


      // Get value from Selected Station
      selectValueStation: function(e) {
        this.valStation = e;

        for(let i in this.itemStation){
          if(e === this.itemStation[i].stationvalue){
            this.stationTitle = this.itemStation[i].stationdisplay;
          }
        }

        this.getDataParam();
      },

      // Get value from Selected Parameters
      checkedParam: function(e) {
          
        if(e.length > 2){
          console.log("Exceeded!");
          this.dialogAlert = true;
          this.arraySelectedParam .splice(2, 1);
        }

        else{
          this.valParameter = e;
        }

      },

      // Get value from Selected Date (by Month)
      selectDate: function(e) {
          this.valDate = e;
      },

      // load Data for Rendering Data Series Highchart
      loadDataChart(data,param,stesen){

        this.dtbody = [];
        this.chartOptions.series = [];
        this.chartOptions.title.text = "WATER QUALITY STATUS FOR "+ this.stationTitle.split("-")[1].toUpperCase() + " ( "+ this.stationTitle.split("-")[0] + ") " + this.tahun;

        // this.chartOptions.title.text = "WATER QUALITY STATUS FOR ("+ this.stationTitle + ") " + this.dateFormat; 

        for (let i = 0; i < data.length; i++) {
          let keyData = Object.keys(data[0])
        }

        this.dtheader = [];
        for(let i of Object.keys(data[0])){
          if(i != "name" && i != "time" && i != "epochdate" ){
            // this.dtheader.push({text:i,value:i});

            if(i == "datetime_a"){
              this.dtheader.push({text:"Date",value:i});
            }

            // else if(i == "station_a"){
            //   this.dtheader.push({text:"STATION ID",value:i});
            // }

            // else if(i == "LOCATION"){
            //   this.dtheader.push({text:"LOCATION",value:i});
            // }

            // else{
            //   this.dtheader.push({text:i,value:i});
          }
        }

        for(let j in this.arraySelectedParam){
          for(let k in this.mainParam){
            if(this.arraySelectedParam[j] === this.mainParam[k].reading){

              // if(this.arraySelectedParam[j] === 'pH'){
              //   this.dtheader.push({text:'Sub-index pH (Index)',value:this.mainParam[k].reading});
              // }

              // else if(this.arraySelectedParam[j] === 'DO_SAT'){
              //   this.dtheader.push({text:'Sub-index Dissolved Oxygen (Index)',value:this.mainParam[k].reading});
              // }

              // else if(this.arraySelectedParam[j] === 'TSS'){
              //   this.dtheader.push({text:'Sub-index Total Suspended Solids (Index)',value:this.mainParam[k].reading});
              // }

              // else{
              //   this.dtheader.push({text:this.mainParam[k].text,value:this.mainParam[k].reading});
              // }

              this.dtheader.push({text:this.mainParam[k].text,value:this.mainParam[k].reading});
            }
          }
        }

        for(let i in data){
         
          // if (data[i]["datetime_a"].includes("T")) {
                           
          //     let dt = data[i]["datetime_a"].split("T")[0];
          //     let t = data[i]["datetime_a"].split("T")[1];

          //     let dt2 = dt.split("-")[2] + "/" + dt.split("-")[1] + "/" + dt.split("-")[0] 
              
          //     data[i]["datetime_a"] = dt2 + " " + t;
          // }

          data[i]["datetime_a"] = this.convertDateFormat(data[i]["datetime_a"].split("T")[0]);
          // data[i]["datetime_a"] = this.convertDateFormat(data[i]["datetime_a"].split("T")[0])  + " " + this.convert12Hours(data[i]["datetime_a"].split("T")[1]);

          this.dtbody.push(data[i]);
        }

        console.log(data);
        

        // Clear first chart before generate another new chart

        for(let i = 0; i < param.length; i++){
          for(let k in this.unit){
            if(param[i] === this.unit[k].name){
              let obj = {
                name: param[i] + " " + ("["+this.unit[k].value+"]" ?? ""),
                data: []
              }
              data.forEach(function(res,k){
                obj.data.push([res.epochdate,res[param[i]]]);
              });
              this.chartOptions.series.push(obj);
            }
          }
        }
      },

      // Generate Chart
      generateChart(){

        this.loadingReport = true;
      
        if(this.valStation === null){
          this.loadingReport = false;
        }

        else{
          this.getDataMonthly(this.valStation,this.dateFormat);

          let bulan = (this.dateFormat).split("-")[1]

          if (bulan.includes("0") && bulan != 10){
            bulan = bulan.substring(1);
          }

          var months = ["", "January", "February", "March", "April", "May", "June", 
            "July", "August", "September", "October", "November", "December" ];

          this.bulan = (months[bulan]).toUpperCase();
          this.tahun = (months[bulan]).toUpperCase() + " " + (this.dateFormat).split("-")[0];
        }

      },

      handler() {
        var args = arguments;
        for (var arg of args) {
          if (arg instanceof Function) {
            arg();
          }
        }
      },
      
    },

    mounted() {
      this.load();
    }

}
</script>



<style>

    /* Section Path File/Modul */
    .pathModul{
      padding-bottom: 0px;
    }

    .pathModul > li:nth-child(3) > a{
      color: black !important;
    }

    /* Section Filtering Input/Select/Button */
    .container-filtering{
      padding: 0px;
    }

    /* .col-input-filter{
      padding: 0px 10px;
    } */

    .col-btn-generateReport{
        margin-bottom: 18px;
    }

    .btn-generate{
        margin: 0px !important;
        /* background: #4495D1 !important; */
    }

    .v-text-field.v-text-field--solo .v-input__control,
    .btn-generate .v-btn .v-btn--contained{
        min-height: 10px;
        margin-top: 10px;
    }

    /* Section Chart Form */
    .highcharts-credits{
      display: none;
    }

    /* Footer Report */
    .one-text-footer{
      margin: 0px 10px;
    }


</style>